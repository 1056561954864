import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
// import Paragraph from '../styledComponents/Paragraph'
import { Checkboxland } from 'checkboxland'

const Main = () => {
  const consoleStyles =
    'font-weight: bold; font-size: 50px;color: red; text-shadow: 3px 3px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113)'

  console.log('%c O haiii! 👋👋👋 ', consoleStyles)

  useEffect(() => {
    const cbl = new Checkboxland({
      dimensions: '29 x 9',
      selector: '#checkboxland',
    })

    let textData = cbl.print('*ALOHA*', { dataOnly: true })
    let paddedTextData = cbl.dataUtils('pad', textData, { top: 1 })

    cbl.marquee(paddedTextData, { interval: 100, repeat: true })
  })

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>MunderstandMe</title>
        <link rel="canonical" href="munderstand.me" />
      </Helmet>
      <Layout>
        {/*
          <Paragraph>
            According to Greek mythology, humans were originally created with
            four arms, four legs and a head with two faces. Fearing their power,
            Zeus split them into two separate parts, condemning them to spend
            their lives in search of their other halves.
          </Paragraph> */}
        <div id="checkboxland"></div>
      </Layout>
    </>
  )
}
export default Main
